import type { AccountUser, Device } from '@nexdynamic/squeegee-common';
import { IOSDevices } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { GlobalFlags } from '../../GlobalFlags';
import { Logger } from '../../Logger';
import { UserDialog } from '../../Users/UserDialog';
import './device-list-item.scss';

export class DeviceListItem {
    @bindable() device: Device;

    protected isMobile = GlobalFlags.isMobile;

    protected localise = ApplicationState.localise;

    @computedFrom('device')
    protected get isNewDevice() {
        const numberedCreatedBy = moment(this.device.createdDate);
        if (moment(numberedCreatedBy).isAfter(moment().subtract(8, 'days'))) {
            return true;
        } else {
            return false;
        }
    }

    @computedFrom('device.name')
    get title() {
        if (this.device.platform === 'web') return 'Web Browser';
        const name = IOSDevices.find(x => x.model === this.device.model)?.name;
        const model = name ? `${name} (${this.device.model})` : this.device.model || '';
        return this.device.manufacturer + ' ' + model;
    }

    @computedFrom('device.avatar')
    get avatar() {
        return this.device.updatedByUser;
    }

    @computedFrom('device.updatedDate')
    get description() {
        const lastSeen = moment(this.device.updatedDate).format('L HH:mm');
        const firstSeen = moment(this.device.createdDate).format('L HH:mm');
        const updatedUserLowerCase = this.device.updatedByUser?.toLocaleLowerCase() || 'Unknown';
        return `${this.localise('audit.last-seen')} ${lastSeen} with user ${updatedUserLowerCase}, first accessed ${firstSeen}`;
    }

    @computedFrom('device.createdDate', 'device.createdBy')
    get tags() {
        const tags = new Array<[color: string, text: string]>();

        const outOfDate = this.device.appVersion && this.device.appVersion !== ApplicationState.version;
        if (this.device.appVersion)
            tags.push([outOfDate ? 'red' : 'green', `${outOfDate ? '⚠️' : ''} Squeegee v${this.device.appVersion}`]);
        const web = this.device.platform === 'web';
        if (this.device.platform || this.device.version) {
            if (web) {
                if (this.device.version) tags.push(['', `${this.device.version}`]);
            } else {
                if (this.device.manufacturer || this.device.model) {
                    tags.push(['', `${this.device.platform} ${this.device.version}`]);
                }
            }
        }
        if ((this.device.serial || 'unknown') !== 'unknown') tags.push(['', `Serial ${this.device.serial}`]);

        tags.push([
            '',
            `Used ${moment(this.device.updatedDate).fromNow()} (${moment(this.device.updatedDate).format('L HH:mm')}) by ${
                this.device.createdBy
            }`,
        ]);

        if (this.device.createdBy !== this.device.updatedByUser) {
            tags.push(['', `Installed ${moment(this.device.createdDate).format('L HH:mm')} by ${this.device.createdBy}`]);
        } else {
            tags.push(['', `Installed ${moment(this.device.createdDate).format('L HH:mm')}`]);
        }

        if (this.isNewDevice) tags.push(['', 'Installed in the last 7 days']);

        if (this.device.reinitialisedDatabase !== undefined) {
            tags.push(['', this.device.reinitialisedDatabase ? 'Fresh Install' : 'Update Install']);
        }

        return tags;
    }

    protected viewUser() {
        try {
            const user = Data.firstOrDefault<AccountUser>('accountuser', user => user.email === this.device.updatedByUser);
            if (!user) throw new Error(`User ${this.device.updatedByUser} not found`);

            new UserDialog(user).show();
        } catch (error) {
            Logger.error('Unable to view device', { device: this.device, error });
        }
    }
}
