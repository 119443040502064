import type { AccountUser, AccountUserProfile, AlertType, TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import { AuthorisedUser, ProductLevel, to2dp } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { AttachmentService } from '../Attachments/AttachmentService';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { Prompt } from '../Dialogs/Prompt';
import { SelectMultiple } from '../Dialogs/SelectMultiple';
import { DurationDialog } from '../Dialogs/TimeOrDuration/DurationDialog';
import { TimeDialog } from '../Dialogs/TimeOrDuration/TimeDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { ReportsService } from '../Insights/ReportsService';
import { Logger } from '../Logger';
import type { IMenuBarAction } from '../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { Utilities } from '../Utilities';
import { isDevMode } from '../isDevMode';
import { t } from '../t';
import { UserAuthorisationService } from './UserAuthorisationService';
import { UserService } from './UserService';

export class UserDialog extends CustomDialog<boolean> {
    protected originallyDeactivated: boolean;
    protected user: AccountUser;
    protected authorisation: AuthorisedUser | undefined;
    protected readonly isInOwnerRole: boolean;
    protected readonly isInAdministratorRole: boolean;
    protected isInReportingRole: boolean;
    protected readonly isInCreatorRole: boolean;
    protected readonly isInJobEditorRole: boolean;
    protected readonly isInCanvasserRole: boolean;
    protected readonly isInPlannerRole: boolean;
    protected readonly isInWorkerRole: boolean;
    protected readonly editorIsOwner: boolean;
    protected readonly editingSelf: boolean;
    protected readonly editingOwner: boolean;
    protected pushAlerts: Array<AlertType> = [];
    protected alertsUpdated = false;
    protected availabilityVisible = false;

    protected moreActions: Array<IMenuBarAction>;

    protected userProfile: AccountUserProfile;

    @computedFrom('useStartTimeWhenOptimising')
    protected get optimisationUsesUserStartTimeDescription() {
        const key = this.useStartTimeWhenOptimising
            ? 'settings.optimisation-uses-user-start-time-description-true'
            : 'settings.optimisation-uses-user-start-time-description-false';
        return ApplicationState.localise(key);
    }

    protected get useStartTimeWhenOptimising() {
        return !!this.userProfile.useStartTimeWhenOptimising;
    }

    protected set useStartTimeWhenOptimising(value: boolean) {
        this.userProfile.useStartTimeWhenOptimising = value;
        this.saveUserProfile();
    }

    protected async changeUserStartTime() {
        const timeDialog = new TimeDialog(this.userProfile.startTime, !this.userProfile.startTime, 'users.can-start-any-time');
        const startTime = await timeDialog.show();
        if (timeDialog.cancelled) return;

        if (!startTime) this.userProfile.startTime = undefined;
        else this.userProfile.startTime = startTime;
        this.saveUserProfile();
    }

    protected async changeUserSkills() {
        const skills = this.user.skills || [];
        const allSkills = ApplicationState.skills.map(skill => ({ text: skill, value: skill }));
        const selectedSkills = allSkills.filter(r => skills.indexOf(r.value) > -1);
        const selectDialog = new SelectMultiple(
            'skills.select-skills',
            allSkills,
            'text',
            'value',
            selectedSkills,
            undefined,
            undefined,
            'skills.no-skills'
        );
        selectDialog.contextMenuBarAction = () => selectDialog.ok(selectDialog.selectedOptions);

        const results = await selectDialog.show();

        if (selectDialog.cancelled) return;

        this.user.skills = results.map(r => r.value);
    }
    protected saveUserProfile() {
        ApplicationState.setSetting('global.account-user-profile', this.userProfile, this.user._id);
    }

    protected get hasReportingRole() {
        return this.isInReportingRole || this.isInAdministratorRole || this.isInOwnerRole;
    }
    protected set hasReportingRole(set: boolean) {
        this.isInReportingRole = set;
    }

    protected get canViewReports() {
        return this.hasReportingRole || this.isInAdministratorRole || this.isInOwnerRole;
    }
    protected set canViewReports(set: boolean) {
        this.hasReportingRole = set;
    }

    constructor(public originalUser: AccountUser, public isNew?: boolean) {
        super('user' + originalUser._id, '../Users/UserDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'details-dialog no-nav-shadow',
            isSecondaryView: true,
        });

        this.user = Utilities.copyObject(originalUser);

        let authorisation = this.user.email && this.user.email.length && UserAuthorisationService.getUserAuthorisation(this.user.email);
        if (!authorisation) {
            authorisation = new AuthorisedUser();
        }
        this.authorisation = authorisation;
        this.originallyDeactivated = !!this.authorisation && this.authorisation.deactivated;

        this.editorIsOwner = !!RethinkDbAuthClient.session && RethinkDbAuthClient.session.email === ApplicationState.dataEmail;
        this.editingSelf = !!RethinkDbAuthClient.session && RethinkDbAuthClient.session.email === this.user.email;
        this.editingOwner = ApplicationState.dataEmail === ((this.user.email && this.user.email.toLowerCase()) || '');

        this.isInOwnerRole = this.isInAnyRole('Owner');
        this.isInAdministratorRole = this.isInAnyRole('Admin');
        this.isInCreatorRole = this.isInAnyRole('Creator');
        this.isInJobEditorRole = this.isInAnyRole('JobEditor');
        this.isInCanvasserRole = this.isInAnyRole('Canvasser');
        this.isInPlannerRole = this.isInAnyRole('Planner');
        this.isInReportingRole = this.isInAnyRole('Reporting');
        this.isInWorkerRole = this.isInAnyRole('Worker');

        if (!this.editingSelf && !isNew) {
            this.moreActions = [
                {
                    tooltip: 'general.delete',
                    handler: async () => {
                        await this.delete();
                    },
                    actionType: 'action-delete',
                    roles: ['Owner', 'Admin'],
                },
            ];
        }

        this.pushAlerts = ApplicationState.getPushAlertsForUser(originalUser.email);
        this.userProfile = ApplicationState.getSetting('global.account-user-profile', {}, this.user._id);
        this.useStartTimeWhenOptimising = this.userProfile.useStartTimeWhenOptimising || false;
    }
    protected localise = ApplicationState.localise;

    protected toggleAvailablitiyVisible() {
        this.availabilityVisible = !this.availabilityVisible;
    }

    protected async setAlertTypes() {
        const pushAlerts = await ApplicationState.selectPushAlerts(this.pushAlerts);
        if (!pushAlerts) return;
        this.pushAlerts = pushAlerts;
        this.alertsUpdated = true;
    }

    private isInAnyRole(role: UserRole) {
        return !!this.authorisation && !!this.authorisation.roles && this.authorisation.roles.indexOf(role) > -1;
    }

    protected delegateSave = () => this.save();

    protected failedValidation: boolean;

    protected changeAvailability(day: 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday') {
        if (!this.user.defaultAvailability)
            this.user.defaultAvailability = {
                Monday: 0,
                Tuesday: 0,
                Wednesday: 0,
                Thursday: 0,
                Friday: 0,
                Saturday: 0,
                Sunday: 0,
            };

        const availability = this.user.defaultAvailability;

        const dayAvailability = availability[day] || 0;
        const duration = moment.duration(dayAvailability, 'hours');
        const formattedTime = duration.hours().toString().padStart(2, '0') + ':' + duration.minutes().toString().padStart(2, '0');
        const timeDialog = new DurationDialog(formattedTime, { seconds: false });
        timeDialog.show().then(result => {
            if (timeDialog.cancelled) return;
            if (!result) delete availability[day];
            let durationToNumber = to2dp(moment.duration(result).asHours());
            if (durationToNumber === 0) delete availability[day];
            if (durationToNumber > 24) durationToNumber = 24;
            availability[day] = durationToNumber;
        });
    }

    public async delete() {
        if (
            !(await new Prompt('general.delete', ApplicationState.localise('users.confirm-delete', { userEmail: this.user.email }), {
                okLabel: 'general.delete',
                cancelLabel: 'general.cancel',
            }).show())
        )
            return;
        try {
            await UserService.delete(this.user);
            this.ok(true);
        } catch (error) {
            new LoaderEvent(false);
            new NotifyUserMessage('notifications.error-during-delete');
            Logger.error(`Error during delete in the user dialog`, { user: this.user, error });
        }
    }

    protected async configureAllowedReports() {
        const reports = (await ReportsService.getAllAvailableReports()) || [];
        const allReports = reports.map(x => ({ text: x.name, value: x.name }));
        const allowedReports = this.user.allowedReports || [];
        const selectedReports = allReports.filter(r => allowedReports.indexOf(r.value) > -1);
        const selectDialog = new SelectMultiple('Select Allowed Reports' as TranslationKey, allReports, 'text', 'value', selectedReports);
        selectDialog.contextMenuBarAction = () => selectDialog.ok(selectDialog.selectedOptions);
        selectDialog.contextMenuBarText = 'OK' as TranslationKey;

        const results = await selectDialog.show();

        if (selectDialog.cancelled) return;

        this.user.allowedReports = results.map(r => r.value);
    }

    public async save() {
        if (!ApplicationState.isInAnyRole(['Owner', 'Admin'])) return this.ok(true);

        if (this.validateAndNotifyUI().length) {
            this.failedValidation = true;
        } else {
            new LoaderEvent(true);
            try {
                const attachments = AttachmentService.fetchAll(this.user._id);
                const attachment = attachments[0];
                if (attachments.length)
                    this.user.avatar = !attachment?.isPublic ? attachment.thumbnail || '' : AttachmentService.getPublicUrl(attachment);

                const email = (this.user.email || '').toLowerCase().trim();
                this.user.email = email;
                if (email) {
                    if (!this.authorisation) {
                        this.authorisation = new AuthorisedUser();
                        this.authorisation.userEmail = email;
                        this.authorisation.userName = this.user.name;
                        this.authorisation.roles = [];
                        this.authorisation.deactivated = this._active === false;
                    } else {
                        this.authorisation.userName = this.user.name;
                        this.authorisation.userEmail = email;
                        this.authorisation.deactivated = this._active === false;
                    }

                    if (!this.authorisation.roles) this.authorisation.roles = [];
                    this.authorisation.roles.splice(0);

                    if (this.isInOwnerRole) this.authorisation.roles.push('Owner');
                    if (this.isInAdministratorRole) this.authorisation.roles.push('Admin');
                    if (this.isInCreatorRole) this.authorisation.roles.push('Creator');
                    if (this.isInJobEditorRole) this.authorisation.roles.push('JobEditor');
                    if (this.isInCanvasserRole) this.authorisation.roles.push('Canvasser');
                    if (this.isInReportingRole) this.authorisation.roles.push('Reporting');
                    if (this.isInPlannerRole) this.authorisation.roles.push('Planner');
                    if (this.isInWorkerRole) this.authorisation.roles.push('Worker');

                    UserAuthorisationService.updateAuthorisation(this.authorisation);
                }

                Object.assign(this.originalUser, this.user);
                await Data.put(this.originalUser);

                const hasPushAccess = this.isInAdministratorRole || this.isInOwnerRole;
                if (this.alertsUpdated || !hasPushAccess)
                    ApplicationState.updatePushAlerts(this.user.email, hasPushAccess ? this.pushAlerts : []);

                new LoaderEvent(false);
                this.ok(true);
            } catch (error) {
                new LoaderEvent(false);
                new NotifyUserMessage('users.save-error');
                Logger.error('Error in save() on user dialog.', { userDialog: this, error });
            }
        }
    }

    protected licenceCheck = UserAuthorisationService.licenceCheck();
    private _canToggleActiveStatus: boolean;
    protected get canToggleActiveStatus() {
        if (this._canToggleActiveStatus === undefined) {
            if (this.editingOwner || this.editingSelf) this._canToggleActiveStatus = false;
            else
                this._canToggleActiveStatus =
                    (!this.isNew && this.authorisation && !this.authorisation.deactivated) || isDevMode() || this.licenceCheck.canAdd;
        }
        return this._canToggleActiveStatus;
    }

    protected allUserLicencesInUse = (() => {
        if (!this.licenceCheck.canAdd) {
            return t('account.all-user-licences-in-use', {
                activeUsers: this.licenceCheck.activeUsers,
                licencedUsers: this.licenceCheck.licencedUsers,
            });
        }
    })();

    protected subscriptionLicenceLimitReached = (() => {
        if (this.licenceCheck.licencedUsers >= 10 && ApplicationState.subscription.product.productLevel < ProductLevel.Infinite) {
            return t('account.subscription-licence-limit-reached', { maxUsers: 10 });
        }
    })();

    private _active: boolean;
    protected get active() {
        if (this._active === undefined) {
            if (this.isNew) this._active = this.licenceCheck.canAdd;
            else this._active = !this.authorisation?.deactivated;
        }
        return this._active;
    }
    protected set active(value: boolean) {
        this._active = value;
        if (this.authorisation) this.authorisation.deactivated = !value;
    }

    private validateAndNotifyUI() {
        const errors = Utilities.validateAndNotifyUI(!!this.user.name.trim(), 'users.name-is-required');
        Utilities.validateAndNotifyUI(!!this.user.email, 'users.email-is-required', errors);

        const validEmail = Utilities.validateEmail(this.user.email);
        Utilities.validateAndNotifyUI(validEmail, 'users.email-is-invalid', errors);

        if (this.user.email && this.user.email !== this.originalUser.email) {
            const userExists = UserService.userExists(this.user.email);
            Utilities.validateAndNotifyUI(!userExists, 'users.email-already-exists', errors);
        }
        return errors;
    }
}
